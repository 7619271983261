<template> 
<seccion-datos :titulo="comercialdatos" @cambios="guardar()">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label >{{ $t('general.nombre') }}</label>
                        <input type="text" v-model="nombre" class="form-control nombre_completo dato" id="nombre_completo" >
                    </div>
                    <div class="form-group">
                        <label >{{ $t('general.telefono') }}</label>
                        <input type="text" v-model="telefono" class="form-control nombre_completo dato" id="nombre_completo" >
                    </div> 
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label >NIF</label>
                        <div class="row">
                            <div class="col-md-3">
                                <select v-model="tipo_identificacion" class="form-control">
                                        <option value="N.I.F.">N.I.F.</option>
                                        <option value="C.I.F.">C.I.F.</option>
                                        <option value="N.I.E.">N.I.E.</option>
                                        <option value="OTROS">{{ $t('general.otros') }}</option>
                                </select>
                            </div>
                            <div class="col-md-9">
                                <input type="text" v-model="nif" class="form-control nombre_completo dato" id="nombre_completo" >
                            </div>
                        </div>
                    </div> 
                    <div class="form-group">
                        <label >Email</label>
                        <input type="text" v-model="email" class="form-control nombre_completo dato" id="nombre_completo" >
                    </div>                       
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <div class="form-group">
                        <label >{{ $t('general.direccion') }}</label>
                        <input type="text" v-model="direccion" class="form-control nombre_completo dato" id="nombre_completo" >
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label >{{ $t('general.cpostal') }}</label>
                        <input type="text" v-model="cp" class="form-control nombre_completo dato" id="nombre_completo" >
                    </div> 
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label >{{ $t('general.ciudad') }}</label>
                        <input type="text" v-model="ciudad" class="form-control nombre_completo dato" id="nombre_completo">
                    </div>   
                </div>
                <div class="col-md-6">
                        <div class="form-group">
                        <label >{{ $t('general.provincia') }}</label>
                        <input type="text" v-model="provincia" class="form-control nombre_completo dato" id="nombre_completo" >
                    </div>   
                </div>
            </div>
</seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id', 'comercial'],
    data() {
        return {
            nombre: '',
            telefono: '',
            nif: '',
            email: '',
            direccion: '',
            cp: '',
            ciudad: '',
            provincia: '',
            tipo_identificacion: 'N.I.F.',
            comercialdatos:"Datos",
        }
    },
    methods: {
        cargarDatosComercial() {
            this.$store.dispatch('cambiarCargando', true);
            this.nombre = this.comercial.nombre_comercial;
            this.telefono = this.comercial.telefono_comercial;
            this.nif = this.comercial.nif_comercial;
            this.email = this.comercial.email_comercial;
            this.direccion = this.comercial.direccion_comercial;
            this.cp = this.comercial.codigo_postal_comercial;
            this.ciudad = this.comercial.ciudad_comercial;
            this.provincia = this.comercial.provincia_comercial;
            this.tipo_identificacion = this.comercial.tipo_identificacion;
            this.comercialdatos = this.$t("general.datos");
            this.$store.dispatch('cambiarCargando', false);

        },
        async guardar() {
            /*  modulos/pwgsapi/index.php/comerciales/:id */
            const api = new PwgsApi;
            const subidadatos = { nombre_comercial: this.nombre, telefono_comercial: this.telefono, nif_comercial: this.nif, email_comercial: this.email, direccion_comercial: this.direccion, codigo_postal_comercial: this.cp, ciudad_comercial: this.ciudad, provincia_comercial: this.provincia, tipo_identificacion: this.tipo_identificacion };
            if (this.id > 0) {
                try {
                    this.$store.dispatch('cambiarGuardando', true);
                    await api.put('comerciales/' + this.comercial.id, subidadatos);
                    this.$store.dispatch('cambiarGuardando', false);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    this.$parent.$parent.obtenerComerciales(this.comercial.id);
                }
                catch (error) {
                    if (error == "Error: Token no válido") {
                        api.get('logout');
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
            else {
                try{
                let res; 
                res = await api.post('comerciales/', subidadatos);
                    this.$router.push('/comerciales/' + res.id);     
                }
                catch (error) {
                    if (error == "Error: Token no válido") {
                        api.get('logout');
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
        }
    },
watch: {
        comercial() {
            this.cargarDatosComercial();
        }
    },
}

</script>
<style>
.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
    color: #555;
    background-color: #e7e7e7;
}
</style>